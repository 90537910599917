import React from "react";
import { useState, useEffect } from "react";
import styles from "./TablePro.module.css";
import { Empty, Form, Input, Spin, Table } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIcon } from "../../../images/search.svg";

export default function TablePro({
    selection,
    selectedRows,
    setSelectedRows,
    setSelectedRecords,
    rowKey,
    onSearch,
    emptyPlaceholder,
    onRowClick,
    searchPlaceholder,
    title,
    headerExtra,
    disableHeaders,
    unselectableRowsCondition,
    disable_reason,
    checkStrictly,
    showSelectedCount,
    ...props
}) {
    const { t } = useTranslation();
    const [currentDisabledRow, setCurrentDisabledRow] = useState(null);
    console.log({ selectedRows });
    const onSelect = (record, selected, selectedRows, ...rest) => {
        console.log({ record, selected, selectedRows });
        if (selection === "checkbox" && checkStrictly === false) {
            if (selected) {
                setSelectedRows((prev) => [...prev, ...selectedRows.filter((it) => !prev.includes(it.id)).map((it) => it.id)]);
                setSelectedRecords?.((prev) => [...prev, ...selectedRows.filter((it) => !prev.map(it => it.id).includes(it.id))]);
            } else {
                setSelectedRows(props.selectedRecords.filter((it) => it.order?.id !== record.order?.id).map((it) => it.id));
                setSelectedRecords?.((prev) => (prev.filter((it) => it.order?.id !== record.order?.id)));
            }
        } else { 
        if (selected) {
            if (selection === "radio") {
                setSelectedRows([record.id]);
                setSelectedRecords?.([record]);
            } else {
                setSelectedRows((prev) => [...prev, record.id]);
                setSelectedRecords?.((prev) => [...prev, record]);
            }
        } else {
            setSelectedRows((prev) => {
                const index = prev.indexOf(record.id);
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            });
            setSelectedRecords?.((prev) => {
                const index = prev.indexOf(record.id);
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            });
        }
        }
    };

    const onSelectAll = (selected, rows) => {
        console.log("SELECT ALL REST");
        const all = unselectableRowsCondition
            ? props.dataSource
                  .filter((record) => !unselectableRowsCondition(record))
                  .map((it) => it.id)
            : props.dataSource.map((it) => it.id);
        if (selected) {
            if (checkStrictly === false) {
                setSelectedRows(rows.map((it) => it.id));
                setSelectedRecords?.(rows);
            } else {
                setSelectedRows(all);
                setSelectedRecords?.(props.dataSource);
            }
        } else {
            setSelectedRows([]);
            setSelectedRecords?.([]);
        }
    };

    useEffect(() => {
        // this effect is to display the disable_reason in a tooltip when hovering over a disabled row
        if (currentDisabledRow && disable_reason) {
            const row = currentDisabledRow.target.closest("tr");
            row.classList.add("position-relative");
            const tooltip = document.createElement("div");
            tooltip.classList.add(
                "tooltip",
                "position-absolute",
                "start-50",
                "top-0",
                "translate-middle-x",
                "shadow",
                "p-3",
                "rounded",
                "opacity-100",
                "fade-in",
                "z-index-100",
                "bg-white"
            );
            tooltip.innerText = disable_reason;
            row.appendChild(tooltip);
            return () => {
                tooltip.remove();
            };
        }
    }, [currentDisabledRow, disable_reason]);

    return (
        <div
            className={`tableContainer ${
                disableHeaders ? "disableHeaders" : ""
            }`}
        >
            {(onSearch || headerExtra || title || showSelectedCount) && (
                <div className={"p-3 row-centered-horizontal"}>
                    {title && (
                        <div style={{ marginInlineEnd: "auto" }}>{title}</div>
                    )}
                    {onSearch && (
                        <Form
                            style={{ width: "50%", paddingInlineEnd: "8px" }}
                            onFinish={({ search }) => {
                                onSearch(search);
                            }}
                        >
                            <Form.Item
                                name="search"
                                style={{ marginBottom: "0 !important" }}
                            >
                                <Input
                                    placeholder={
                                        searchPlaceholder ?? t("search")
                                    }
                                    prefix={
                                        <button
                                            style={{
                                                marginTop: "-3px",
                                                marginInlineEnd: "8px",
                                            }}
                                            type={"submit"}
                                            className={"action"}
                                        >
                                            <SearchIcon />
                                        </button>
                                    }
                                />
                            </Form.Item>
                        </Form>
                    )}
                    <div className="mb-2 ms-auto d-flex align-items-center gap-2">
                        {showSelectedCount && (
                            <div className="d-flex align-items-end ms-auto">
                                {t("Selected")}: {selectedRows.length}
                            </div>
                        )}
                        {headerExtra}
                    </div>
                </div>
            )}
            <Table
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (e) => {
                            console.log({ record });
                            onRowClick?.(rowIndex, record, e);
                        },
                    };
                }}
                indentSize={20}
                preserveSelectedRowKeys
                {...props}
                locale={{
                    emptyText: !props.loading && (
                        <div className="table-empty-placeholder">
                            {emptyPlaceholder ?? (
                                <div>
                                    <Empty />
                                    <div>{t("empty")}</div>
                                </div>
                            )}
                        </div>
                    ),
                }}
                rowSelection={
                    selection
                        ? {
                              selectedRowKeys: selectedRows,
                              type:
                                  selection === "radio" ? "radio" : "checkbox",
                              fixed: true,
                              getCheckboxProps: (record) => ({
                                  disabled:
                                      unselectableRowsCondition?.(record) ??
                                      false,
                                  onMouseEnter: (e) => {
                                      unselectableRowsCondition?.(record) &&
                                          setCurrentDisabledRow(e);
                                  },
                                  onMouseLeave: () => {
                                      setCurrentDisabledRow(null);
                                  },
                              }),
                              onSelect,
                              onSelectAll,
                              checkStrictly
                          }
                        : undefined
                }
                rowKey={rowKey ?? ((record) => record.id)}
                loading={{
                    spinning: props.loading ?? false,
                    indicator: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Spin />
                        </div>
                    ),
                }}
            />
        </div>
    );
}
