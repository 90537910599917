import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SeeMoreButton from "../../../../components/SeeMoreButton";
import useLanguage from "../../../../hooks/useLanguage";

export const useUnConfirmedRequestsColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("orderId"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">#{row.id}</span>,
        },
        {
            title: t("store"),
            dataIndex: ["seller", "store_name"],
        },
        {
            title: t("shipmentType"),
            dataIndex: "type",
        },
        {
            title: t("Pickup City"),
            dataIndex: "id",
            render: (_, row) => <span>{lang === "en" ? row.seller_address?.area.city.name : row.seller_address?.area.city.name_ar}</span>,
        },
        {
            title: t("Delivery City"),
            dataIndex: "id",
            render: (_, row) => <span>{lang === "en" ? row.client_address?.city_name : row.client_address?.city_name_ar}</span>,
        },
        {
            title: t("itemQuantity"),
            dataIndex: "ticket_count",
        },
        {
            title: t("orderValue"),
            dataIndex: "sub_total",
        },
        {
            title: t("Request Date"),
            dataIndex: "create_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]} - {row.create_date?.split("T")[1]}
                </p>
            ),
        },
        {
            title: t("paymentMethod"),
            dataIndex: "payment_method",
            render: (_, row) => (
                <strong>
                    {row.payment_method == "Online" ? (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="onlineBtn"
                        >
                            {row.payment_method}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="cashBtn"
                        >
                            {row.payment_method}
                        </Button>
                    )}
                </strong>
            ),
        },
        {
            title: t("actions"),
            show: true,
            render: (_, row) => (
                <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                    <SeeMoreButton />
                </NavLink>
            ),
        },
    ];
};

export const useConfirmedRequestsColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );

    return [
        {
            title: t("orderId"),
            dataIndex: ["order", "id"],
            render: (_, row) => (
                <div 
                    style={{ minWidth: "100px" }}
                className="tableID">#{row.order?.id}</div>
            ),
        },
        {
            title: t("ticketId"),
            dataIndex: "id",
            headerName: "Ticket ID",
            width: 200,
            render: (_, row) => <span className="tableID">#{row.id}</span>,
        },
        {
            title: t("store"),
            dataIndex: ["order", "seller", "store_name"],
        },
        {
            title: t("createDate"),
            dataIndex: "create_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]} - {row.create_date?.split("T")[1].split(":").slice(0, 2).join(":")}
                </p>
            ),
        },
        {
            title: t("postponedDate"),
            dataIndex: "new_date",
        },
        // {
        //     title: t("deliveryRange"),
        //     dataIndex: "delivery_range",
        //     render: (_, row) => (
        //         <>
        //             {row.order?.delivery_range && (
        //                 <>
        //                     {row.order?.delivery_range.min} -
        //                     {row.order?.delivery_range.max}
        //                 </>
        //             )}
        //         </>
        //     ),
        // },
        {
            title: t("Pickup City"),
            dataIndex: ["order", "seller_address", "area_names"],
            render: (_, row) => <span>{lang === "en" ? _?.en : _?.ar}</span>,

        },
        {
            title: t("Delivery City"),
            dataIndex: ["order", "client_address", "area"],
            render: (_, row) => <span>{lang === "en" ? _?.name : _?.name_ar}</span>,
        },
        {
            title: t("itemQuantity"),
            dataIndex: "quantity",
        },
        // {
        //     title: t("dimensions"),
        //     render: (_, row) => (
        //         <>
        //             {typeof row.length === "undefined" ||
        //             typeof row.width === "undefined" ||
        //             typeof row.height === "undefined" ? (
        //                 <></>
        //             ) : (
        //                 <>
        //                     {row.length}x{row.width}x{row.height}
        //                 </>
        //             )}
        //         </>
        //     ),
        // },
        {
            title: t("weight"),
            dataIndex: "weight",
        },
        {
            title: t("specialLabel"),
            dataIndex: "special_label",
        },
        {
            title: t("feedbackComment"),
            dataIndex: ["comment", "text"],
        },
        {
            title: t("orderValue"),
            dataIndex: ["order", "sub_total"],
            render: (_, row) => (
                <>
                    {row.order?.sub_total} {cur}
                </>
            ),
        },
        {
            title: t("shipmentFees"),
            field: ["order", "delivery_fees"],
            render: (_, row) => (
                <>
                    {row.order?.delivery_fees} {cur}
                </>
            ),
        },
        {
            title: t("courierId"),
            dataIndex: ["order", "courier", "id"],
        },
        {
            title: t("courierName"),
            dataIndex: ["order", "courier", "user", "name"],
        },
        {
            title: t("courierBranch"),
            dataIndex: ["order", "courier", "branch", "name"],
        },
        {
            title: t("courierVehicle"),
            dataIndex: ["order", "courier", "vehicle"],
        },
        {
            title: t("courierPhone"),
            dataIndex: ["order", "courier", "user", "phone"],
        },
        {
            title: t("sellerId"),
            dataIndex: ["order", "seller", "id"],
        },
        {
            title: t("store"),
            dataIndex: ["order", "seller", "store_name"],
        },
        {
            title: t("sellerAddress"),
            dataIndex: ["order", "seller_address", "address"],
        },
        {
            title: t("customerId"),
            dataIndex: ["order", "client", "id"],
        },
        {
            title: t("customerName"),
            dataIndex: ["order", "client", "name"],
        },
        {
            title: t("customerPhone"),
            dataIndex: ["order", "client", "phone"],
        },
        {
            title: t("customerAddress"),
            dataIndex: ["order", "client_address", "address"],
        },
        {
            title: t("status"),
            dataIndex: ["order", "assigned_state"],
            render: (_, row) => (
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        {row.order?.assigned_state}
                    </Button>
                </strong>
            ),
        },
        {
            title: t("paymentMethod"),
            dataIndex: ["order", "payment_method"],
            render: (_, row) => (
                <strong>
                    {/* {console.log(row)} */}
                    {row.payment_method == "1" ? (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="onlineBtn"
                        >
                            Online
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="cashBtn"
                        >
                            Cash
                        </Button>
                    )}
                </strong>
            ),
        },
        {
            show: true,
            field: "action",
            headerName: "Action",
            width: 230,
            render: (_, row) => (
                <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                    <SeeMoreButton />
                </NavLink>
            ),
        },
    ];
};
